<template>
  <LoadingBar v-if="isLoading"></LoadingBar>
  <div class="flex flex-col h-full">
    <page-header>
      <page-title title="Marketing">
        <button
          @click="toggleExportModal"
          class="inline-flex items-center focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
          >
            <path
              fill-rule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            /></svg
          ><span> Export Email Address</span>
        </button>
      </page-title>
    </page-header>

    <div class="flex flex-col flex-grow">
      <div class="overflow-x-auto">
        <div
          class="
            inline-block
            min-w-full min-h-full
            overflow-hidden
            align-middle
            border-b border-gray-200
            sm:rounded-lg
          "
        >
          <div
            class="px-12 py-12 overflow-y-scroll bg-white message-container"
            id="msgContainer"
            ref="msgContainer"
            @scroll="handleScroll"
          >
            <div
              v-for="message in fetchedMessages"
              :key="message"
              class="flex flex-col pt-4"
            >
              <div
                v-if="message.marketing.toLowerCase() == 'sms'"
                class="flex-col"
              >
                <div class="float-right md:w-2/5 x-sm:w-1/2 sm:w-1/2 lg:w-1/3">
                  <div>
                    <div class="ml-4 text-sm text-left text-gray-400">
                      {{ date(message.created_at) }}
                    </div>
                    <div
                      class="
                        flex
                        px-5
                        py-3
                        m-0
                        text-white
                        bg-qgo-primary-bg
                        rounded-3xl
                      "
                    >
                      <p class="text-left">{{ message.content }}</p>
                    </div>
                    <div
                      class="
                        flex flex-wrap
                        items-center
                        justify-between
                        ml-4
                        text-sm text-gray-400
                        align-middle
                      "
                    >
                      <div class="flex align-middle">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="gray"
                            class="w-5 h-5"
                          >
                            <path
                              d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                            />
                          </svg>
                        </span>

                        <span class="items-center ml-2">
                          {{ message.count }} Customers reached
                        </span>
                      </div>
                      <div class="justify-end mr-5 text-right">
                        Sent as: {{ message.marketing }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="flex-col">
                <div class="float-left md:w-2/5 x-sm:w-1/2 sm:w-1/2 lg:w-1/3">
                  <div class="ml-4 text-sm text-left text-gray-400">
                    {{ date(message.created_at) }}
                  </div>
                  <div
                    class="px-5 py-3 text-white bg-qgo-secondary-bg rounded-3xl"
                  >
                    <p class="text-left">{{ message.content }}</p>
                  </div>
                  <div class="flex ml-4 text-sm text-gray-400">
                    Sent as: {{ message.marketing }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- subscription -inactive -->
          <div
            v-if="billingStatus != 1"
            class="
              flex flex-wrap flex-1
              my-5
              rounded-2xl
              x-sm:mx-4
              sm:mx-4
              md:mx-12
              lg:mx-16
              xl:mx-24
              2xl:mx-32
              x-sm:flex-col
              sm:flex-row
            "
          >
            <div class="flex flex-col content-start flex-1 px-2 py-2">
              <h4 v-if="billingStatus == 0" class="text-left text-lg font-bold">
                WELCOME TO YOUR ACCOUNT
              </h4>
              <p v-if="billingStatus == 0" class="text-left">
                To begin using your subscription, Please add a payment method on
                the Billing screen.
              </p>
              <h4 v-if="billingStatus == 2" class="text-left text-lg font-bold">
                Subscription inactive
              </h4>
              <p v-if="billingStatus == 2" class="text-left">
                Please reactivate your subscription to send Marketing messages.
              </p>
              <h4 v-if="billingStatus == 3" class="text-left text-lg font-bold">
                Subscription paused
              </h4>
              <p v-if="billingStatus == 3" class="text-left">
                Please reactivate your subscription to send Marketing messages.
              </p>
            </div>
            <div class="flex flex-col justify-center mx-1 flex-0">
              <router-link
                tag="span"
                active-class="active"
                to="/vendor/billing"
                exact
              >
                <a
                  class="
                    flex
                    px-12
                    py-2
                    text-white
                    border-b-2
                    rounded-lg
                    bg-qgo-primary-bg
                    focus:outline-none
                    text-center
                    justify-center
                  "
                >
                  Edit Billing
                </a></router-link
              >
            </div>
          </div>
          <!-- subscription -inactive -->

          <!-- <form> -->
          <div
            v-else
            class="
              flex flex-wrap flex-1
              my-5
              bg-white
              border border-gray-300
              rounded-2xl
              x-sm:mx-4
              sm:mx-4
              md:mx-12
              lg:mx-16
              xl:mx-24
              2xl:mx-32
              x-sm:flex-col
              sm:flex-row
            "
          >
            <div class="flex content-start flex-1 px-2 py-2">
              <textarea
                class="
                  flex-1
                  w-full
                  py-2
                  mt-1
                  border-transparent
                  resize-none
                  form-textarea
                  focus:ring-0 focus:border-white
                "
                :class="
                  validators.marketingMessage
                    ? 'placeholder-red-600'
                    : 'placeholder-gray-500'
                "
                rows="3"
                placeholder="Your message here.."
                :value="marketingMessage"
                @input="sendMessage($event)"
              ></textarea>
            </div>
            <div class="flex flex-col justify-center mx-1 flex-0">
              <button
                @click="toggleConfirmDistribution('sms')"
                class="
                  flex
                  px-3
                  py-3
                  text-white
                  border-b-2
                  rounded-2xl
                  bg-qgo-primary-bg
                  hover:bg-green-700
                  focus:outline-none
                "
              >
                <svg
                  class="w-5 h-5 mx-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 22.148 22.48"
                >
                  <path
                    id="Path_180"
                    data-name="Path 180"
                    d="M8.545,23.678a2.249,2.249,0,0,0,1.592-.723L14.053,19.6h5.439a4.885,4.885,0,0,0,5.146-5.146V6.344A4.885,4.885,0,0,0,19.492,1.2H7.637A4.885,4.885,0,0,0,2.49,6.344v8.105A4.953,4.953,0,0,0,7.256,19.6h.068v2.764A1.177,1.177,0,0,0,8.545,23.678Z"
                    transform="translate(-2.49 -1.197)"
                    fill="#fff"
                  />
                </svg>
                Send as: SMS Message (${{ price }}/user)
              </button>
              <button
                class="
                  flex
                  px-3
                  py-3
                  text-white
                  border-b-2
                  rounded-2xl
                  bg-qgo-secondary-bg
                  hover:bg-gray-800
                  focus:outline-none
                "
                @click="toggleConfirmDistribution('push_notification')"
              >
                <svg
                  class="w-5 h-5 mx-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20.234 20.234"
                >
                  <path
                    id="Path_181"
                    data-name="Path 181"
                    d="M19.443,7.926a3.314,3.314,0,0,0,3.281-3.281,3.286,3.286,0,1,0-6.572,0A3.316,3.316,0,0,0,19.443,7.926ZM8.613,21.588h7c2.344,0,3.682-.391,4.707-1.426s1.416-2.324,1.416-4.707v-6.2a5.081,5.081,0,0,1-2.3.557,5.193,5.193,0,0,1-5.166-5.166,5.081,5.081,0,0,1,.557-2.3H8.613c-2.344,0-3.672.391-4.707,1.426S2.49,6.09,2.49,8.463v6.992c0,2.383.381,3.682,1.416,4.707S6.27,21.588,8.613,21.588Z"
                    transform="translate(-2.49 -1.354)"
                    fill="#fff"
                  />
                </svg>
                Send as: Push Notification
              </button>
            </div>
          </div>
          <!-- </form> -->

          <!-- modal send sms -->
          <transition name="slide" mode="out-in">
            <modal-view v-if="msgModal">
              <ConfirmDistribution
                @messageSent="dataupdated"
              ></ConfirmDistribution>
            </modal-view>
          </transition>

          <!-- modal for export -->
          <div v-if="exportModal">
            <modal-view>
              <ExportEmailAddress></ExportEmailAddress>
            </modal-view>
          </div>
        </div>
      </div>
    </div>

    <page-footer>
      <footer-nav></footer-nav>
    </page-footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PageHeader from "@/components/pages/Header.vue";
import PageTitle from "@/views/Vendor/PageTitle.vue";
import ConfirmDistribution from "@/views/Vendor/Modal/ConfirmDistribution.vue";
import ExportEmailAddress from "@/views/Vendor/Modal/ExportEmailAddress.vue";
import PageFooter from "@/components/pages/Footer.vue";
import FooterNav from "@/views/Vendor/FooterNav.vue";
import LoadingBar from "@/components/loader/Loader.vue";
import ModalView from "@/components/Modal.vue";
import moment from "moment";

export default defineComponent({
  setup() {
    return {};
  },
  components: {
    PageHeader,
    PageTitle,
    PageFooter,
    FooterNav,
    LoadingBar,
    ModalView,
    ConfirmDistribution,
    ExportEmailAddress,
  },
  data() {
    return {
      fetchedMessages: [],
      price: "",
      billingStatus: 0,
    };
  },
  methods: {
    checkBillingStatus() {
      this.$http
        .get("subscription_items")
        .then((response) => {
          if (response && response.data.status) {
            this.billingStatus = response.data.subscription_code;
            //0: no card added, 1: Active, 2: Inactive, 3: Paused
            // if billing status is active then enable marketing tab
            if (this.billingStatus == 1) {
            }
          } else {
            this.billingStatus = 0;
          }
        })
        .catch((error) => {
          this.$store.commit("marketing/setLoading", false);
        });
    },
    dataupdated(data) {
      this.fetchedMessages = data;
      setTimeout(() => {
        this.scrollDown();
      }, 0);
    },
    getAllmessages() {
      this.$store.commit("marketing/setLoading", true);
      this.$http
        .get("message/show")
        .then((response) => {
          if (response && response.status) {
            this.fetchedMessages = response.data.data;
            this.price = response.data.sms_price;
            localStorage.setItem("smsprice", this.price);
            setTimeout(() => {
              this.scrollDown();
            }, 0);
            // this.scrollDown();
            // this.handleScroll();
            this.$store.commit("marketing/setLoading", false);
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
          this.$store.commit("marketing/setLoading", false);
        });
    },
    handleScroll() {
      let messages = this.$refs.msgContainer;
      if (this.firstTime) {
        this.$store.commit("marketing/setFirstTime", false);
        messages.scrollTop = messages.scrollHeight;
      } else {
        if (messages.scrollTop == 0) {
          if (this.limit < messages.length) {
            this.fetchedMessages = messages.scrollTop = messages.scrollHeight;
          }
          this.$store.commit("marketing/setLimit", 5);
        }
      }
    },
    scrollDown() {
      let messages = this.$refs.msgContainer;
      messages.scrollTop = messages.scrollHeight;
    },
    date: function (date) {
      return moment(date).format("MMM D, YYYY, h:mm:ss a");
    },
    sendMessage(e) {
      this.$store.commit("marketing/setMarketingMessage", e.target.value);
    },
    toggleConfirmDistribution(option) {
      if (this.marketingMessage) {
        this.$store.commit("marketing/setMsgModal", !this.msgModal);
        this.$store.commit("marketing/setOption", option);
      } else {
        this.$store.commit("marketing/setValidatorsMarketingMessage", true);
      }
    },
    toggleExportModal() {
      this.$store.commit("marketing/setExportModal", !this.exportModal);
    },
  },
  computed: {
    // fetchedMessages() {
    //   let data = this.$store.getters["marketing/inputData"];
    //   let filteredMsg = this.limit ? data.slice(0, this.limit) : data;
    //   console.log(filteredMsg);
    //   return filteredMsg;
    // },
    marketingMessage() {
      return this.$store.getters["marketing/marketingMessage"];
    },
    msgModal() {
      return this.$store.getters["marketing/msgModal"];
    },
    exportModal() {
      return this.$store.getters["marketing/exportModal"];
    },
    firstTime() {
      return this.$store.getters["marketing/firstTime"];
    },
    limit() {
      return this.$store.getters["marketing/limit"];
    },
    validators() {
      return this.$store.getters["marketing/validators"];
    },
    isLoading() {
      return this.$store.getters["marketing/isLoading"];
    },
  },
  mounted() {
    this.getAllmessages();
    this.checkBillingStatus();
    // this.scrollDown();
  },
  created() {
    // this.$store.dispatch("marketing/fetchMarketingInitialData").then(
    //   response => {
    //     console.log(response);
    //   },
    //   error => {
    //     console.log(error);
    //     this.$swal.fire({
    //       icon: "error",
    //       text: "Something went wrong while fetching Messages!",
    //       timer: 5000
    //     });
    //   }
    // );
  },
});
</script>
